import { useTranslation } from 'react-i18next';
import utils from '../core/utils';
import './solutions.scss';
import solutionsArrow from '../assets/icon/solutions-arrow.svg';
import { motion } from 'framer-motion';
import { motionHeight, motionWidth } from 'src/core/animation';

const Solutions = () => {
  return (
    <motion.div initial='offscreen' whileInView='onscreen' viewport={{ once: false, amount: 0.3 }}>
      <motion.section id='solutions' className='Solutions' variants={{}}>
        <div className='solutions-title'>
          <b>THINGPLUS</b> SOLUTIONS
        </div>
        <SolutionGroup />
      </motion.section>
    </motion.div>
  );
};

const SolutionGroup = () => {
  const { t } = useTranslation();
  const contents = [
    { title: 'Asset', content: t('solutions.solutions-1'), href: 'https://www.daliworks.net/product/#main-sec03' },
    { title: 'Farming', content: t('solutions.solutions-2'), href: 'https://www.daliworks.net/product/#main-sec03' },
    {
      title: 'Environment',
      content: t('solutions.solutions-3'),
      href: 'https://www.daliworks.net/product/#main-sec03',
    },
    { title: 'Industry', content: t('solutions.solutions-5'), href: 'https://www.daliworks.net/product/#main-sec03' },
    { title: 'Safety', content: t('solutions.solutions-4'), href: 'https://www.daliworks.net/product/#main-sec03' },
  ];
  let solutionAreaVariants = motionWidth({ from: '20%', to: '22.5%' });
  if (utils.checkMobile()) {
    solutionAreaVariants = {};
  }
  let template = [];
  for (let i in contents) {
    template.push(
      <motion.a
        href={contents[i].href}
        target='_blank'
        key={i}
        className='solution-area'
        initial='offscreen'
        whileHover='onscreen'
        variants={solutionAreaVariants}>
        <div className='area-inner'>
          <div className='solution-number'>{utils.addZero(+i + 1, 2)}</div>
          <div className='solution-content'>
            <div className='solution-title'>{contents[i].title}</div>
            <div className='solution-content'>{contents[i].content}</div>
            <img className='solution-icon' src={solutionsArrow} />
          </div>
        </div>
      </motion.a>
    );
  }
  return <div className='solution-group'>{template}</div>;
};

export default Solutions;
