import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { motion, useCycle } from 'framer-motion';
import './mobile-menu.scss';
import utils from 'src/core/utils';
import { Link } from 'react-scroll';
import youtube from '../../assets/icon/youtube-black.svg';
import naverBlog from '../../assets/icon/naver-blog-black.svg';
import facebook from '../../assets/icon/facebook-black.svg';
import { useTranslation } from 'react-i18next';
import translateSetting from 'src/core/translate-setting';

interface POS {
  width: number;
  height: number;
  sizeX: number;
  sizeY: number;
}

const sidebar = {
  open: (pos: POS) => {
    let ratio = pos.sizeX;
    if (pos.sizeX < pos.sizeY) {
      ratio = pos.sizeY;
    }
    return {
      clipPath: `circle(${ratio * 2 + 100}px at ${pos.width - 32}px 32px)`,
      opacity: 1,
      transition: {
        type: 'spring',
        stiffness: 20,
        restDelta: 5,
      },
    };
  },
  closed: (pos: POS) => {
    return {
      clipPath: `circle(30px at ${pos.width - 32}px 32px)`,
      opacity: 0,
    };
  },
};

const navigationVariants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

const menuListVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const navList: string[] = ['about', 'features', 'solutions', 'introduction', 'daliworks'];

export const MobileMenu = () => {
  const [isOpen, toggleOpen] = useCycle(false, true);
  const containerRef = useRef<HTMLElement>(null);
  const { width, height, sizeX, sizeY } = useDimensions(containerRef);
  if (utils.checkMobile()) {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  useEffect(() => {
    let target = window.document.querySelector('body');
    if (target) {
      if (isOpen) {
        target.classList.add('not-scroll');
      } else {
        target.classList.remove('not-scroll');
      }
    }
  }, [isOpen]);

  return (
    <motion.nav
      className={'MobileMenu' + (isOpen ? ' open' : ' closed')}
      initial={false}
      animate={isOpen ? 'open' : 'closed'}
      ref={containerRef}>
      <motion.div className='background' custom={{ width, height, sizeX, sizeY }} variants={sidebar}>
        <div>
          <MenuHeader />
          <Navigation toggleOpen={toggleOpen} />
        </div>
        <MenuFooter />
      </motion.div>
      <LanguageToggle />
      <MenuToggle toggle={() => toggleOpen()} />
    </motion.nav>
  );
};

const Path = (props: any) => <motion.path fill='transparent' strokeWidth='2' stroke='white' {...props} />;

const MenuToggle = (props: { toggle: any }) => (
  <button className='m-menu-button' onClick={props.toggle}>
    <svg width='28' height='28' viewBox='0 0 23 23'>
      <Path
        variants={{
          closed: { d: 'M 2 2.5 L 20 2.5' },
          open: { d: 'M 3 16.5 L 17 2.5' },
        }}
      />
      <Path
        d='M 2 9.423 L 20 9.423'
        variants={{
          closed: { opacity: 1 },
          open: { opacity: 0 },
        }}
        transition={{ duration: 0.1 }}
      />
      <Path
        variants={{
          closed: { d: 'M 2 16.346 L 20 16.346' },
          open: { d: 'M 3 2.5 L 17 16.346' },
        }}
      />
    </svg>
  </button>
);
const LanguageToggle = () => {
  let [language, setLanguage] = useState(translateSetting.getCurrentLanguage());
  return (
    <button
      className='m-nav-button'
      onClick={() => {
        translateSetting.changeLanguage();
        setLanguage(translateSetting.getCurrentLanguage());
      }}>
      {language}
    </button>
  );
};

const MenuHeader = () => {
  let [language, setLanguage] = useState(translateSetting.getCurrentLanguage());
  return (
    <div className='menu-header'>
      <div
        className={'language' + (language === 'KOR' ? ' active' : '')}
        onClick={() => {
          translateSetting.changeLanguage('ko');
          setLanguage(translateSetting.getCurrentLanguage());
        }}>
        KOR
      </div>
      <div
        className={'language' + (language === 'ENG' ? ' active' : '')}
        onClick={() => {
          translateSetting.changeLanguage('en');
          setLanguage(translateSetting.getCurrentLanguage());
        }}>
        ENG
      </div>
    </div>
  );
};
const MenuFooter = () => {
  const { t } = useTranslation();
  return (
    <div className='menu-footer'>
      <a href='https://blog.naver.com/thingplus' target='_blank' rel='noreferrer'>
        <img src={naverBlog} alt='naver blog' />
      </a>
      <a href='https://www.youtube.com/channel/UCa7T7hj3F0x7brM6htUasyQ' target='_blank' rel='noreferrer'>
        <img src={youtube} alt='youtube' />
      </a>
      <a href='https://www.facebook.com/Thingplus/' target='_blank' rel='noreferrer'>
        <img src={facebook} alt='facebook' />
      </a>
      <a href='https://www.daliworks.net/' target='_blank' rel='noreferrer'>
        {t('footer.homepage')}
      </a>
    </div>
  );
};

const useDimensions = (ref: MutableRefObject<any>) => {
  let defaultPosition = utils.getDocumentOffset();
  let defaultSize = utils.getWindowInnerSize();
  const dimensions = useRef({
    width: defaultPosition[0],
    height: defaultPosition[1],
    sizeX: defaultSize[0],
    sizeY: defaultSize[1],
  });

  useEffect(() => {
    dimensions.current.width = ref.current.offsetWidth;
    dimensions.current.height = ref.current.offsetHeight;
  });

  return dimensions.current;
};

const Navigation = (props: { toggleOpen: any }) => {
  let template = [];
  for (let i in navList) {
    template.push(<MenuItem i={i} value={navList[i]} key={i} toggleOpen={props.toggleOpen} />);
  }
  return <motion.ul variants={navigationVariants}>{template}</motion.ul>;
};

export const MenuItem = (props: { i: any; value: any; toggleOpen: any }) => {
  return (
    <motion.li className='test' variants={menuListVariants} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
      <Link activeClass='active' to={props.value} spy={false} smooth={'easeInOutQuart'} duration={1500}>
        <span onClick={() => props.toggleOpen()}>{props.value.toUpperCase()}</span>
      </Link>
    </motion.li>
  );
};
