import './App.scss';
import Header from './components/header';
import Main from './components/main';
import Footer from './components/footer';
import Popup from './components/popup';
import PopupPage from './components/popup-page';

import utils from './core/utils';
import { createContext, MutableRefObject, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import useResizeObserver from '@react-hook/resize-observer';

const SizeContext = createContext(0);

const App = () => {
  const appRef = useRef<HTMLDivElement>(null);
  const size = useSize(appRef);

  const handleScroll = () => {
    if (!utils.checkMobile()) {
      const position = window.pageYOffset;
      const height = window.document.documentElement.offsetHeight;
      const innerHeight = window.innerHeight;

      let remote = window.document.querySelector('.Remote');
      if (remote) {
        if (position < height - innerHeight - 260 && position > 0) {
          remote.classList.add('fix');
        } else {
          remote.classList.remove('fix');
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  }, []);

  // Popup State
  const [inquiryPopup, setInquiryPopup] = useState(false);

  return (
    <div className="App" ref={appRef}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Header />
                <Main setInquiryPopup={setInquiryPopup} />
                <Footer />
                <Popup setInquiryPopup={setInquiryPopup} inquiryPopup={inquiryPopup} />
              </>
            }
          ></Route>
          <Route
            path="contact"
            element={
              <>
                <PopupPage />
              </>
            }
          ></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
};

const useSize = (target: MutableRefObject<any>) => {
  const [size, setSize] = useState(null as any);

  useLayoutEffect(() => {
    setSize(target.current.getBoundingClientRect());
  }, [target]);

  // Where the magic happens
  useResizeObserver(target, (entry) => setSize(entry.contentRect));
  return size;
};

export default App;
