import { createPortal } from 'react-dom';
import { Dispatch, MutableRefObject, SetStateAction, useRef, useState } from 'react';
import bg1 from '../assets/background/bg-inquiry-popup.jpg';
import icon1 from '../assets/icon/cloese-icon.svg';

import './popup.scss';
import { useTranslation } from 'react-i18next';
import translateSetting from 'src/core/translate-setting';

const Popup = ({
  setInquiryPopup,
  inquiryPopup,
}: {
  setInquiryPopup: Dispatch<SetStateAction<boolean>>;
  inquiryPopup: boolean;
}) => {
  return createPortal(
    <div className="Popup">{inquiryPopup && <InquiryPopup setInquiryPopup={setInquiryPopup} />}</div>,
    document.body
  );
};

const InquiryPopup = ({ setInquiryPopup }: { setInquiryPopup: Dispatch<SetStateAction<boolean>> }) => {
  const { t } = useTranslation();

  let curLng = translateSetting.getCurrentLanguage();
  let country = '한국어';
  let category = 'Thingplus 랜딩 페이지 문의';
  let tab = 'Thingplus Inquiry';
  let emailSubject = 'Thingplus 랜딩페이지로부터 새로운 문의가 접수되었습니다.';
  let emailFooter = '- Thingplus 랜딩페이지 문의 -';

  if (curLng === 'ENG') {
    country = '영어';
  }
  if (curLng === 'JPN') {
    country = '일본어';
  }

  let company: MutableRefObject<string> = useRef('');
  let name: MutableRefObject<string> = useRef('');
  let position: MutableRefObject<string> = useRef('');
  let phone: MutableRefObject<string> = useRef('');
  let email: MutableRefObject<string> = useRef('');
  let msg: MutableRefObject<string> = useRef('');
  let path: MutableRefObject<string> = useRef('');

  let [alertCompany, setAlertCompany] = useState(false);
  let [alertName, setAlertName] = useState(false);
  let [alertPhone, setAlertPhone] = useState(false);
  let [alertEmail, setAlertEmail] = useState(false);
  let [alertMsg, setAlertMsg] = useState(false);
  let [alertPath, setAlertPath] = useState(false);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.id == 'company') {
      company.current = e.target.value;

      if (e.target.value == '' || e.target.value == undefined) {
        setAlertCompany(true);
      } else {
        setAlertCompany(false);
      }
    } else if (e.target.id == 'name') {
      name.current = e.target.value;
      if (e.target.value == '' || e.target.value == undefined) {
        setAlertName(true);
      } else {
        setAlertName(false);
      }
    } else if (e.target.id == 'position') {
      position.current = e.target.value;
    } else if (e.target.id == 'phone') {
      phone.current = e.target.value;
      if (e.target.value == '' || e.target.value == undefined) {
        setAlertPhone(true);
      } else {
        setAlertPhone(false);
      }
    } else if (e.target.id == 'email') {
      email.current = e.target.value;
      let regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

      if (!regex.test(email.current)) {
        setAlertEmail(true);
      } else {
        setAlertEmail(false);
      }
    } else if (e.target.id == 'msg') {
      msg.current = e.target.value;
      if (e.target.value == '' || e.target.value == undefined) {
        setAlertMsg(true);
      } else {
        setAlertMsg(false);
      }
    } else if (e.target.id == 'path') {
      path.current = e.target.value;
      if (e.target.value == '' || e.target.value == undefined) {
        setAlertPath(true);
      } else {
        setAlertPath(false);
      }
    }
  }

  function handleSubmit() {
    let complete = true;
    let regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-z]{2,6}$/;

    if (company.current == '' || company.current == undefined) {
      setAlertCompany(true);
      complete = false;
    }
    if (name.current == '' || name.current == undefined) {
      setAlertName(true);
      complete = false;
    }
    if (phone.current == '' || phone.current == undefined) {
      setAlertPhone(true);
      complete = false;
    }

    if (!regex.test(email.current)) {
      setAlertEmail(true);
      complete = false;
    }
    if (msg.current == '' || msg.current == undefined) {
      setAlertMsg(true);
      complete = false;
    }
    if (path.current == '' || path.current == undefined) {
      setAlertPath(true);
      complete = false;
    }

    if (complete) {
      sendRequest({
        country: country,
        category: category,
        company: company.current,
        name: name.current,
        position: position.current,
        phone: phone.current,
        email: email.current,
        msg: msg.current,
        path: path.current,
        tab,
        emailSubject,
        emailFooter,
      });
      window.alert(t('popup.inquiry.alert-text-1'));
      setInquiryPopup(false);
    } else {
      setInquiryPopup(true);
    }
  }

  async function sendRequest(obj: object) {
    const response = await fetch('https://ruyvdtlqki.execute-api.ap-northeast-2.amazonaws.com/contact', {
      method: 'POST',
      body: JSON.stringify(obj),
      headers: {
        'content-Type': 'application/json',
      },
    });
    const data = await response.json();
    console.log(data);
  }

  return (
    <div className="inquiry-popup">
      <div className="content-box">
        <img
          className="close-btn"
          src={icon1}
          onClick={() => {
            setInquiryPopup(false);
          }}
        />
        <div className="left-section">
          <img className="image" src={bg1} />
        </div>
        <div className="right-section">
          <div className="top-section">
            <div className="big-text">{t('popup.inquiry.title-text-0')}</div>
            <div className="middle-text">{t('popup.inquiry.title-text-1')}</div>
            <div className="small-text">{t('popup.inquiry.title-text-2')}</div>
            <div className="small-text">{t('popup.inquiry.title-text-3')}</div>
          </div>
          <form className="bottom-section">
            <div className="category-box">
              <input
                id="company"
                type="string"
                placeholder={t('popup.inquiry.input-text-0')}
                onChange={handleChange}
              ></input>
              {alertCompany && <div className="alert-text">{t('popup.inquiry.alert-text-0')}</div>}
              <input
                id="name"
                type="string"
                placeholder={t('popup.inquiry.input-text-1')}
                onChange={handleChange}
              ></input>
              {alertName && <div className="alert-text">{t('popup.inquiry.alert-text-0')}</div>}
              <input
                id="position"
                type="string"
                placeholder={t('popup.inquiry.input-text-2')}
                onChange={handleChange}
              ></input>
              <input
                id="phone"
                type="string"
                placeholder={t('popup.inquiry.input-text-3')}
                onChange={handleChange}
              ></input>
              {alertPhone && <div className="alert-text">{t('popup.inquiry.alert-text-0')}</div>}
              <input
                id="email"
                type="string"
                placeholder={t('popup.inquiry.input-text-4')}
                onChange={handleChange}
              ></input>
              {alertEmail && <div className="alert-text">{t('popup.inquiry.alert-text-2')}</div>}
              <input
                id="msg"
                type="string"
                placeholder={t('popup.inquiry.input-text-5')}
                onChange={handleChange}
              ></input>
              {alertMsg && <div className="alert-text">{t('popup.inquiry.alert-text-0')}</div>}
              <div className="label-text">{t('popup.inquiry.input-text-6')}</div>
              <input
                id="path"
                className="small-text"
                type="string"
                placeholder={t('popup.inquiry.input-text-7')}
                onChange={handleChange}
              ></input>
              {alertPath && <div className="alert-text">{t('popup.inquiry.alert-text-0')}</div>}
            </div>
            <div className="submit-btn" onClick={handleSubmit}>
              {t('popup.inquiry.btn-text-0')}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Popup;
