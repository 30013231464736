function addZero(value: number, pos: number) {
  let valAsStr = value.toString();
  for (let i = valAsStr.length; i < pos; i++) {
    valAsStr = '0' + valAsStr;
  }
  return valAsStr;
}

function createMarkup(str: string) {
  return { __html: str };
}

function getDocumentOffset() {
  return [window.document.documentElement.offsetWidth, window.document.documentElement.offsetHeight];
}

function getWindowInnerSize() {
  return [window.innerWidth, window.innerHeight];
}

function checkMobile() {
  const width = window.innerWidth;
  if (width < 960) {
    return true;
  }
  return false;
}

function checkPortrait() {
  let orientation = window.orientation;
  if (orientation === 0) {
    return true;
  }
  return false;
}

function swipePower(offset: number, velocity: number) {
  return Math.abs(offset) * velocity;
}

export default {
  addZero,
  createMarkup,
  getDocumentOffset,
  getWindowInnerSize,
  checkMobile,
  checkPortrait,
  swipePower,
};
