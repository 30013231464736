import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { motion, useCycle } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import translateSetting from 'src/core/translate-setting';
import './language-toggle.scss';

export const LanguageToggleMenu = () => {
  const [isOpen, toggleOpen] = useCycle(false, true);
  let [language, setLanguage] = useState(translateSetting.getCurrentLanguage());

  return (
    <motion.div className="LanguageToggleMenu" initial={false} animate={isOpen ? 'open' : 'closed'}>
      <LanguageToggle toggle={() => toggleOpen()} />
      <LanguageList toggleOpen={toggleOpen} />
    </motion.div>
  );
};
const navigationVariants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

const menuListVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const Path = (props: any) => (
  <motion.path
    d="M109.121-841.759a8.829 8.829 0 0 1-3.54-.719 9.249 9.249 0 0 1-2.9-1.958 9.249 9.249 0 0 1-1.958-2.9 8.829 8.829 0 0 1-.719-3.54 8.813 8.813 0 0 1 .719-3.547 9.273 9.273 0 0 1 1.958-2.9 9.25 9.25 0 0 1 2.9-1.958 8.83 8.83 0 0 1 3.54-.719 8.814 8.814 0 0 1 3.547.719 9.273 9.273 0 0 1 2.9 1.958 9.273 9.273 0 0 1 1.958 2.9 8.814 8.814 0 0 1 .719 3.547 8.829 8.829 0 0 1-.719 3.54 9.249 9.249 0 0 1-1.958 2.9 9.272 9.272 0 0 1-2.9 1.958 8.813 8.813 0 0 1-3.547.719zm0-1.46a13.129 13.129 0 0 0 1.237-1.966 11.222 11.222 0 0 0 .818-2.167h-4.11a12.252 12.252 0 0 0 .827 2.2 11.256 11.256 0 0 0 1.228 1.933zm-1.859-.264a10.408 10.408 0 0 1-.991-1.8 11.786 11.786 0 0 1-.683-2.069h-3.257a7.526 7.526 0 0 0 2.04 2.513 6.853 6.853 0 0 0 2.891 1.356zm3.718 0a6.853 6.853 0 0 0 2.891-1.357 7.526 7.526 0 0 0 2.04-2.513h-3.257a15.08 15.08 0 0 1-.729 2.078 12.481 12.481 0 0 1-.945 1.792zm-9.253-5.31h3.573q-.09-.535-.131-1.049t-.041-1.037q0-.522.041-1.037t.131-1.05h-3.569a7.6 7.6 0 0 0-.212 1.017 7.708 7.708 0 0 0-.074 1.069 7.708 7.708 0 0 0 .074 1.069 7.6 7.6 0 0 0 .208 1.018zm5.009 0h4.77q.09-.535.131-1.04t.041-1.046q0-.541-.041-1.046t-.131-1.04h-4.77q-.09.535-.131 1.04t-.041 1.046q0 .541.041 1.046t.131 1.04zm6.21 0h3.569a7.6 7.6 0 0 0 .212-1.017 7.708 7.708 0 0 0 .074-1.069 7.708 7.708 0 0 0-.074-1.069 7.6 7.6 0 0 0-.212-1.017h-3.569q.09.535.131 1.05t.041 1.037q0 .522-.041 1.037t-.131 1.048zm-.292-5.612h3.257a7.507 7.507 0 0 0-2.026-2.513 7.07 7.07 0 0 0-2.9-1.366 11.933 11.933 0 0 1 .982 1.833 13.2 13.2 0 0 1 .687 2.046zm-5.588 0h4.11a12.335 12.335 0 0 0-.841-2.218 11.208 11.208 0 0 0-1.214-1.915 11.208 11.208 0 0 0-1.214 1.915 12.334 12.334 0 0 0-.841 2.218zm-4.735 0h3.257a13.2 13.2 0 0 1 .692-2.046 11.933 11.933 0 0 1 .982-1.833 7 7 0 0 0-2.91 1.371 7.608 7.608 0 0 0-2.022 2.508z"
    transform="translate(-97.121 862.879)"
    {...props}
  />
);

const LanguageToggle = (props: { toggle: any }) => {
  return (
    <button className="m-language-button" onClick={props.toggle}>
      <svg width="28" height="28" viewBox="0 0 24 24">
        <Path
          variants={{
            closed: { fill: 'white' },
            open: { fill: '#ffb400' },
          }}
        />
      </svg>
    </button>
  );
};

const LanguageList = (props: { toggleOpen: any }) => {
  let [language, setLanguage] = useState(translateSetting.getCurrentLanguage());
  let template = [];
  for (let i in translateSetting.supportedLngs) {
    template.push(
      <MenuItem
        i={i}
        value={translateSetting.supportedLngs[i]}
        key={i}
        setLanguage={setLanguage}
        toggleOpen={props.toggleOpen}
      />
    );
  }
  return (
    <motion.ul className="language-list" variants={navigationVariants}>
      {template}
    </motion.ul>
  );
};

export const MenuItem = (props: { i: any; value: any; setLanguage: any; toggleOpen: any }) => {
  return (
    <motion.li className="menu-item" variants={menuListVariants} whileHover={{ color: '#ffb400' }}>
      <span
        onClick={() => {
          translateSetting.changeLanguage(props.value.value);
          props.setLanguage(translateSetting.getCurrentLanguage());
          props.toggleOpen();
        }}
      >
        {props.value.label.toUpperCase()}
      </span>
    </motion.li>
  );
};
