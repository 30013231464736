import { useTranslation } from 'react-i18next';
import youtube from '../assets/icon/youtube.svg';
import naverBlog from '../assets/icon/naver-blog.svg';
import facebook from '../assets/icon/facebook.svg';
import { Link } from 'react-scroll';
import { motion } from 'framer-motion';
import './footer.scss';

const Footer = () => {
  return (
    <footer className='Footer'>
      <FooterTop />
      <FooterBottom />
    </footer>
  );
};

const FooterTop = () => {
  const { t } = useTranslation();
  return (
    <section className='footer-top'>
      <div className='footer-top-left'>
        <div className='info'>
          <label>{t('footer.address-label')}</label>
          <span>{t('footer.address')}</span>
        </div>
        <div className='info-box'>
          <div className='info'>
            <label>E-mail</label>
            <span>contact@daliworks.net</span>
          </div>
          <div className='info'>
            <label>TEL</label>
            <span>+82. 2. 2274. 3254</span>
          </div>
          <div className='info'>
            <label>FAX</label>
            <span>+82. 2. 2274. 3253</span>
          </div>
        </div>
      </div>
      <div className='footer-top-right'>
        <a className='subscribe-button' href='https://page.stibee.com/subscriptions/140195' target='_blank'>
          <button>{t('footer.subscribe-button')}</button>
        </a>
        <Remote />
      </div>
    </section>
  );
};

const FooterBottom = () => {
  const { t } = useTranslation();
  return (
    <section className='footer-bottom'>
      <div className='copyright'>Copyright 2022. Daliworks Inc. All Rights Reserved.</div>
      <div className='link-container'>
        <motion.a
          href='https://blog.naver.com/thingplus'
          target='_blank'
          whileHover={{ scale: 1.2 }}
          whileTap={{ scale: 0.8 }}>
          <img src={naverBlog} />
        </motion.a>
        <motion.a
          href='https://www.youtube.com/channel/UCa7T7hj3F0x7brM6htUasyQ'
          target='_blank'
          whileHover={{ scale: 1.2 }}
          whileTap={{ scale: 0.8 }}>
          <img src={youtube} />
        </motion.a>
        <motion.a
          href='https://www.facebook.com/Thingplus/'
          target='_blank'
          whileHover={{ scale: 1.2 }}
          whileTap={{ scale: 0.8 }}>
          <img src={facebook} />
        </motion.a>
        <motion.a
          href='https://www.daliworks.net/'
          target='_blank'
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.8 }}>
          {t('footer.homepage')}
        </motion.a>
      </div>
    </section>
  );
};

const Remote = () => {
  return (
    <motion.button className={'Remote Remote-here'} whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }}>
      <Link activeClass='active' to='Hero' spy={true} smooth={'easeInOutQuart'} duration={1500}>
        <i className='material-icons'>arrow_upward</i>
      </Link>
    </motion.button>
  );
};

export default Footer;
