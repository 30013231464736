import { useTranslation } from 'react-i18next';
import './about.scss';
import utils from '../core/utils';
import possible1 from '../assets/icon/possible-1.svg';
import possible2 from '../assets/icon/possible-2.svg';
import possible3 from '../assets/icon/possible-3.svg';
import possible4 from '../assets/icon/possible-4.svg';
import archtectureImg from '../assets/background/architecture.jpg';
import archtectureMobile from '../assets/background/architecture-mobile.png';
import { motion } from 'framer-motion';
import { motionX, motionY } from 'src/core/animation';
import { useState } from 'react';

const About = () => {
  return (
    <section id='about' className='About'>
      <AboutIntroSection />
      <AboutPossibleSection />
      <AboutArchitectureSection />
    </section>
  );
};

const AboutIntroSection = () => {
  const { t } = useTranslation();
  return (
    <motion.section
      className='about-intro-section'
      initial='offscreen'
      whileInView='onscreen'
      viewport={{ once: false, amount: 0.3 }}>
      <section className='left-section'>
        <div className='chapter' dangerouslySetInnerHTML={utils.createMarkup(t('common.chapter'))}></div>
        <div className='content-box'>
          <motion.div
            className='title'
            initial='offscreen'
            whileInView='onscreen'
            dangerouslySetInnerHTML={utils.createMarkup(t('about.title'))}
            variants={motionX()}></motion.div>
          <div className='underline'></div>
          <motion.div className='content' initial='offscreen' whileInView='onscreen' variants={motionX()}>
            {t('about.content')}
          </motion.div>
        </div>
      </section>
      <motion.section className='right-section' variants={{}}></motion.section>
    </motion.section>
  );
};

const AboutPossibleSection = () => {
  return (
    <motion.div initial='offscreen' whileInView='onscreen' viewport={{ once: false, amount: 0.3 }}>
      <section className='about-possible-section'>{PossibleCard()}</section>
    </motion.div>
  );
};

const AboutArchitectureSection = () => {
  const { t } = useTranslation();

  let contentBoxConfig,
    targetImage = archtectureImg;
  if (utils.checkMobile()) {
    targetImage = archtectureMobile;
  } else {
    contentBoxConfig = { from: 50, to: 0 };
  }
  return (
    <div>
      <section className='about-architecture-section'>
        <section className='architecture-header'>
          <motion.div
            className='title-box'
            initial='offscreen'
            whileInView='onscreen'
            viewport={{ once: false, amount: 0.3 }}
            variants={motionX()}>
            THINGPLUS <br />
            <b>ARCHITECTURE</b>
          </motion.div>
          <motion.pre
            className='content-box'
            initial='offscreen'
            whileInView='onscreen'
            viewport={{ once: false, amount: 0.3 }}
            variants={motionX(contentBoxConfig)}>
            {t('about.architecture-content')}
          </motion.pre>
        </section>
        <section className='architecture-main'>
          <img className='architecture-image' src={targetImage} alt='architecture'></img>
        </section>
        <section className='architecture-footer'>
          <div className='description-box'>
            <div className='description-title-box'>
              <b className='title-number'>01</b>
              <span className='title'>{t('about.architecture-describe-1-title')}</span>
            </div>
            <div className='description-content-box'>{t('about.architecture-describe-1-content')}</div>
          </div>
          <div className='description-box'>
            <div className='description-title-box'>
              <b className='title-number'>02</b>
              <span className='title'>{t('about.architecture-describe-2-title')}</span>
            </div>
            <div className='description-content-box'>{t('about.architecture-describe-2-content')}</div>
          </div>
        </section>
      </section>
    </div>
  );
};

function PossibleCard() {
  const { t } = useTranslation();
  const cardList: any[] = [
    { svg: possible1, title: t('about.possible-1-title'), content: t('about.possible-1-content') },
    { svg: possible2, title: t('about.possible-2-title'), content: t('about.possible-2-content') },
    { svg: possible3, title: t('about.possible-3-title'), content: t('about.possible-3-content') },
    { svg: possible4, title: t('about.possible-4-title'), content: t('about.possible-4-content') },
  ];
  let template = [];
  let delay = 0;
  for (let i in cardList) {
    if (!utils.checkMobile()) {
      delay = +i * 0.3;
    }
    template.push(
      <motion.div
        key={i}
        initial='offscreen'
        whileInView='onscreen'
        viewport={{ once: false, amount: 0.3 }}
        className='card'
        variants={motionY({
          transition: {
            type: 'tween',
            delay: delay,
            duration: 1,
          },
        })}>
        <div className='card-number'>{utils.addZero(+i + 1, 2)}</div>
        <img className='card-icon' src={cardList[i].svg} alt='icon'></img>
        <h3 className='card-title'>{cardList[i].title}</h3>
        <div className='vertical-line'></div>
        <pre className='card-content'>{cardList[i].content}</pre>
      </motion.div>
    );
  }
  return <div className='group'>{template}</div>;
}

export default About;
