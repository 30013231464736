import About from '../pages/about';
import Features from '../pages/features';
import Solutions from '../pages/solutions';
import Introduction from '../pages/introduction';
import Company from '../pages/company';
import Hero from '../pages/hero';
import './main.scss';
import { Dispatch, SetStateAction } from 'react';

const Main = ({ setInquiryPopup }: { setInquiryPopup: Dispatch<SetStateAction<boolean>> }) => {
  return (
    <main className="Main">
      <Hero setInquiryPopup={setInquiryPopup} />
      <About />
      <Features />
      <Solutions />
      <Introduction />
      <Company />
    </main>
  );
};

export default Main;
