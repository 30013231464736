import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import ko_KR from '../assets/locale/ko_KR.json';
import en_EN from '../assets/locale/en_EN.json';
import i18next from 'i18next';

type LANGUAGES = 'ko' | 'en';

const supportedLngs = [
  { label: 'ENG', value: 'en' },
  { label: 'KOR', value: 'ko' },
];

const setTranslate = () => {
  i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      // the translations
      // (tip move them in a JSON file and import them,
      // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
      resources: {
        en: en_EN,
        ko: ko_KR,
      },
      fallbackLng: 'en',
      supportedLngs: supportedLngs.map((item) => item.value),

      interpolation: {
        escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      },
    });
};

const changeLanguage = (language?: string) => {
  if (language) {
    i18next.changeLanguage(language);
  } else {
    if (i18next.language == 'ko') {
      i18next.changeLanguage('en');
    } else {
      i18next.changeLanguage('ko');
    }
  }
};

const getCurrentLanguage = () => {
  const Labels = {
    en: 'ENG',
    ko: 'KOR',
  };
  return Labels[i18next.language as LANGUAGES];
};

export default { setTranslate, changeLanguage, getCurrentLanguage, supportedLngs };
