import { useEffect, useRef, useState } from 'react';
import { motion, AnimatePresence, useAnimationControls } from 'framer-motion';
import { wrap } from 'popmotion';
import bg1 from '../../assets/background/bg-1.jpg';
import bg2 from '../../assets/background/bg-2.jpg';
import bg3 from '../../assets/background/bg-3.jpg';
import left from '../../assets/icon/left-arrow.svg';
import right from '../../assets/icon/right-arrow.svg';
import pause from '../../assets/icon/pause.svg';
import play from '../../assets/icon/play.svg';
import { useTranslation } from 'react-i18next';
import utils from '../../core/utils';
import './slide-animation.scss';
import { motionBackgroundPosition, motionSlide } from 'src/core/animation';

const DELAY_MS = 5000;
const swipeConfidenceThreshold = 1000;

export const SlideAnimation = () => {
  const { t } = useTranslation();
  const controls = useAnimationControls();
  const [[page, direction], setPage] = useState([0, 0]);
  const [autoplay, setAutoplay] = useState(true);
  const [customDelay, setCustomDelay] = useState(DELAY_MS);
  const [targetTime, setTargetTime] = useState(0);

  useTimeout(
    () => {
      if (autoplay) {
        paginate(1);
      }
    },
    customDelay,
    page
  );

  useEffect(() => {
    if (autoplay) {
      controls.start(() => ({
        width: '100%',
        transition: { duration: customDelay / 1000, ease: 'linear' },
      }));
      setTargetTime(new Date().getTime() + DELAY_MS);
    }
  }, [page]);

  const paginate = (newDirection: number) => {
    setPage([page + newDirection, newDirection]);
    setCustomDelay(DELAY_MS);
    controls.set({ width: '0%' });
    controls.start(() => ({
      width: '100%',
      transition: { duration: customDelay / 1000, ease: 'linear' },
    }));
  };
  const pauseSlideShow = () => {
    setAutoplay(!autoplay);
    if (autoplay) {
      controls.stop();
      setCustomDelay(targetTime - new Date().getTime() + 1000);
    } else {
      controls.start(() => ({
        width: '100%',
        transition: { duration: customDelay / 1000, ease: 'linear' },
      }));
    }
  };

  const slideImages = [
    { image: bg1, content: t('hero.bg-content-1') },
    { image: bg2, content: t('hero.bg-content-2') },
    { image: bg3, content: t('hero.bg-content-3') },
  ];

  const imageIndex = wrap(0, slideImages.length, page);

  return (
    <div className="SlideAnimation">
      <AnimatePresence initial={false} custom={direction}>
        <motion.div
          className="slide-container"
          key={page}
          custom={direction}
          variants={motionSlide()}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: 'tween' },
            opacity: { duration: 0.3 },
          }}
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={0.05}
          onDragEnd={(e, { offset, velocity }) => {
            const swipe = utils.swipePower(offset.x, velocity.x);

            if (swipe < -swipeConfidenceThreshold) {
              paginate(1);
            } else if (swipe > swipeConfidenceThreshold) {
              paginate(-1);
            }
          }}
        >
          <div className="slide-content">
            <motion.div
              style={{ backgroundImage: `url(${slideImages[imageIndex].image})` }}
              initial="offscreen"
              whileInView="onscreen"
              variants={motionBackgroundPosition()}
            >
              <div
                className="content-box"
                dangerouslySetInnerHTML={utils.createMarkup(slideImages[imageIndex].content)}
              ></div>
            </motion.div>
          </div>
        </motion.div>
      </AnimatePresence>
      <div className="slide-controller">
        <div className="current-page">{utils.addZero(imageIndex + 1, 2)}</div>
        <div className="progress">
          <div className="back">
            <motion.div className="front" animate={controls}></motion.div>
          </div>
        </div>
        <div className="total-page">{utils.addZero(slideImages.length, 2)}</div>
        <div className="action-box">
          <img src={left} alt="left arrow" onClick={() => paginate(-1)} />
          <img
            src={autoplay ? pause : play}
            onClick={() => {
              pauseSlideShow();
            }}
          />
          <img src={right} alt="right arrow" onClick={() => paginate(1)} />
        </div>
      </div>
    </div>
  );
};

const useTimeout = (callback: any, delay: number, index: number) => {
  const savedCallback: any = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    };

    const timerId = setTimeout(tick, delay);
    return () => clearTimeout(timerId);
  });
};
