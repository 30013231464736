import { useTranslation } from 'react-i18next';
import './company.scss';
import utils from '../core/utils';
import { motion } from 'framer-motion';
import { motionX } from 'src/core/animation';

const Company = () => {
  const { t } = useTranslation();

  return (
    <div>
      <section id='daliworks' className='Company'>
        <div></div>
        <div className='company-container-wrapper'>
          <div className='company-container'>
            <motion.div
              className='company-name'
              initial='offscreen'
              whileInView='onscreen'
              viewport={{ once: false, amount: 0.3 }}
              variants={motionX()}>
              Daliworks
            </motion.div>
            <motion.div
              className='company-title'
              dangerouslySetInnerHTML={utils.createMarkup(t('company.title'))}
              initial='offscreen'
              whileInView='onscreen'
              viewport={{ once: false, amount: 0.3 }}
              variants={motionX()}></motion.div>
            <div className='horizontal-line'></div>
            <motion.pre
              className='company-content'
              initial='offscreen'
              whileInView='onscreen'
              viewport={{ once: false, amount: 0.3 }}
              variants={motionX()}>
              {t('company.content')}
            </motion.pre>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Company;
