import './header.scss';
import { Link } from 'react-scroll';
import { MobileMenu } from './shared/mobile-menu';
import { LanguageToggleMenu } from './shared/language-toggle';
import translateSetting from 'src/core/translate-setting';
import { useTranslation } from 'react-i18next';

const Header = () => {
  return (
    <header className="Header">
      <section className="logo-section">
        <img className="logo" src={require('../assets/logo/logo.png')} alt="Logo" />
      </section>
      <section className="nav-section">
        <Nav />
        <LanguageToggleMenu />
        <MobileMenu />
      </section>
    </header>
  );
};

function Nav() {
  const { t } = useTranslation();
  const navList: string[] = ['about', 'features', 'solutions', 'introduction', 'daliworks'];
  let language = translateSetting.getCurrentLanguage();

  let target = window.document.querySelector('body');
  let title = window.document.querySelector('title');
  if (title) {
    title.innerText = t('Welcome to Thingplus');
  }
  if (language == 'KOR') {
    target?.classList.add('not-kr');
  } else {
    target?.classList.remove('not-kr');
  }

  let template = [];
  for (let i in navList) {
    template.push(
      <li key={i} className="nav-item">
        <Link activeClass="active" to={navList[i]} spy={true} smooth={'easeInOutQuart'} duration={1500}>
          {navList[i].toUpperCase()}
        </Link>
      </li>
    );
  }
  return <ul className="nav">{template}</ul>;
}

export default Header;
