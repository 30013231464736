import { MotionDelayConfig, MotionFromToConfig } from '@models/animation.model';
import { Variants } from 'framer-motion';

export function motionX(config?: MotionFromToConfig) {
  let from, to, transition, offscreen, onscreen;
  if (config) {
    from = config.from;
    to = config.to;
    transition = config.transition;
    offscreen = config.offscreen;
    onscreen = config.onscreen;
  }
  const variants: Variants = {
    offscreen: offscreen || {
      x: from || -50,
      opacity: 0,
    },
    onscreen: onscreen || {
      x: to || 0,
      opacity: 1,
      transition: transition || {
        type: 'tween',
        duration: 1,
      },
    },
  };
  return variants;
}

export function motionY(config?: MotionFromToConfig) {
  let from, to, transition;
  if (config) {
    from = config.from;
    to = config.to;
    transition = config.transition;
  }
  const variants: Variants = {
    offscreen: {
      y: from || -50,
      opacity: 0,
    },
    onscreen: {
      y: to || 0,
      opacity: 1,
      transition: transition || {
        type: 'tween',
        duration: 1,
      },
    },
  };
  return variants;
}

export function motionRepeatX(config?: MotionFromToConfig) {
  let from, to;
  if (config) {
    from = config.from;
    to = config.to;
  }
  const variants: Variants = {
    offscreen: {
      x: from || -50,
    },
    onscreen: {
      x: to || 0,
      transition: {
        type: 'tween',
        duration: 10,
        repeat: Infinity,
      },
    },
  };
  return variants;
}

export function motionScale(config?: MotionFromToConfig) {
  let from, to, transition;
  if (config) {
    from = config.from;
    to = config.to;
    transition = config.transition;
  }
  const variants: Variants = {
    offscreen: {
      scale: from || 0.9,
    },
    onscreen: {
      scale: to || 1,
      transition: transition || {
        type: 'tween',
        duration: 1,
      },
    },
  };
  return variants;
}

export function motionWidth(config?: MotionFromToConfig) {
  let from, to, transition;
  if (config) {
    from = config.from;
    to = config.to;
    transition = config.transition;
  }
  const variants: Variants = {
    offscreen: {
      width: from || 1,
    },
    onscreen: {
      width: to || 1.2,
      transition: transition || {
        type: 'tween',
        duration: 0.3,
      },
    },
  };
  return variants;
}

export function motionHeight(config?: MotionFromToConfig) {
  let from, to;
  if (config) {
    from = config.from;
    to = config.to;
  }
  const variants: Variants = {
    offscreen: {
      height: from || 1,
    },
    onscreen: {
      height: to || 1.2,
      transition: {
        type: 'tween',
        duration: 0.3,
      },
    },
  };
  return variants;
}

export function motionBackgroundSize(config?: MotionFromToConfig) {
  let from, to;
  if (config) {
    from = config.from;
    to = config.to;
  }
  const variants: Variants = {
    offscreen: {
      backgroundSize: from || '105% auto',
    },
    onscreen: {
      backgroundSize: to || '125% auto',
      transition: {
        type: 'tween',
        duration: 1,
      },
    },
  };
  return variants;
}

export function motionBackgroundPosition(config?: MotionFromToConfig) {
  let from, to;
  if (config) {
    from = config.from;
    to = config.to;
  }
  const variants: Variants = {
    offscreen: {
      backgroundPosition: from || '50% 50%',
    },
    onscreen: {
      backgroundPosition: to || '75% 50%',
      transition: {
        type: 'tween',
        duration: 10,
      },
    },
  };
  return variants;
}

export function motionChildDelayContainer(config?: MotionDelayConfig) {
  let delayChildren, staggerChildren;
  if (config) {
    delayChildren = config.delayChildren;
    staggerChildren = config.staggerChildren;
  }
  const variants: Variants = {
    offscreen: {},
    onscreen: {
      transition: {
        delayChildren: delayChildren || 0.4,
        staggerChildren: staggerChildren || 0.3,
      },
    },
  };
  return variants;
}

export function motionProgress(config?: MotionFromToConfig) {
  let from, to, transition;
  if (config) {
    from = config.from;
    to = config.to;
    transition = config.transition;
  }
  const variants: Variants = {
    offscreen: {
      width: '0px',
    },
    onscreen: {
      width: '180px',
      transition: transition || {
        type: 'tween',
        duration: 0.3,
      },
      transitionEnd: {
        width: '0px',
      },
    },
    stop: {
      width: '0px',
      transition: {
        type: 'tween',
        duration: 0,
      },
    },
  };
  return variants;
}

export function motionSlide() {
  const variants = {
    enter: (direction: number) => {
      return {
        x: direction > 0 ? 10 : -10,
        opacity: 0,
        transition: {
          type: 'tween',
          duration: 0.5,
        },
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
      transition: {
        type: 'tween',
        duration: 0.5,
      },
    },
    exit: (direction: number) => {
      return {
        zIndex: 0,
        x: direction < 0 ? 10 : -10,
        opacity: 0,
        transition: {
          type: 'tween',
          duration: 0.5,
        },
      };
    },
  };
  return variants;
}
