import './hero.scss';
import { useTranslation } from 'react-i18next';
import { SlideAnimation } from '../components/shared/slide-animation';
import { Dispatch, SetStateAction } from 'react';

const Hero = ({ setInquiryPopup }: { setInquiryPopup: Dispatch<SetStateAction<boolean>> }) => {
  const { t } = useTranslation();
  return (
    <div className="Hero">
      <SlideAnimation />
      <div className="user-controller">
        <a href="https://www.youtube.com/channel/UCa7T7hj3F0x7brM6htUasyQ" target="_blank" rel="noreferrer">
          <button className="link-button">{t('hero.introduction-video')}</button>
        </a>

        <a
          rel="noreferrer"
          onClick={() => {
            setInquiryPopup(true);
          }}
        >
          <button className="link-button">{t('hero.question')}</button>
        </a>
      </div>
    </div>
  );
};

export default Hero;
