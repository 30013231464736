import './introduction.scss';
import scenarioKor from '../assets/background/scenario-kor.png';
import scenarioEng from '../assets/background/scenario-eng.png';
import { useTranslation } from 'react-i18next';
import utils from '../core/utils';
import { motion } from 'framer-motion';
import { motionX, motionY, motionChildDelayContainer } from 'src/core/animation';
import translateSetting from 'src/core/translate-setting';
import { useState } from 'react';

const Introduction = () => {
  return (
    <section id='introduction' className='Introduction'>
      <IntroductionMain />
      <IntroductionScenario />
    </section>
  );
};

const IntroductionMain = () => {
  return (
    <motion.div
      className='introduction-main'
      initial='offscreen'
      whileInView='onscreen'
      viewport={{ once: false, amount: 0.3 }}>
      <motion.div className='introduction-title' variants={motionX()}>
        INTRODUCTION <b>GUIDE</b>
      </motion.div>
      <motion.div
        className='introduction-group'
        variants={motionChildDelayContainer({ delayChildren: 0.3, staggerChildren: 0.2 })}
        initial='offscreen'
        whileInView='onscreen'>
        <IntroductionCard tag='Sass' />
        <IntroductionCard tag='Solution' />
      </motion.div>
    </motion.div>
  );
};

type TAG = 'Sass' | 'Solution';

const IntroductionCard = (prop: { tag: TAG }) => {
  const { t } = useTranslation();
  let tags = {
    Sass: 1,
    Solution: 2,
  };
  return (
    <motion.div
      className={'introduction-card introduction-card-' + tags[prop.tag]}
      variants={motionY({ from: '50%', to: 0 })}>
      <div className='card-container'>
        <span className='card-tag'>{prop.tag}</span>
        <div className='card-title'>{t(`introduction.card-${tags[prop.tag]}-title`)}</div>
        <div className='horizontal-line'></div>
        <GetTarget index={tags[prop.tag]} />
        <GetBenefit index={tags[prop.tag]} />
      </div>
    </motion.div>
  );
};

const GetTarget = (prop: { index: number }) => {
  const { t } = useTranslation();
  const target = ['a', 'b', 'c', 'd'];
  let template = [];
  for (let i in target) {
    template.push(
      <div key={i} className='card-target'>
        <b>{target[i]}. </b> {t(`introduction.card-${prop.index}-${target[i]}`)}
      </div>
    );
  }
  return <div className='target'>{template}</div>;
};

const GetBenefit = (prop: { index: number }) => {
  const { t } = useTranslation();
  const benefit = ['1', '2'];
  let template = [];
  for (let i in benefit) {
    template.push(
      <div
        key={i}
        className='card-benefit'
        dangerouslySetInnerHTML={utils.createMarkup(
          +i + 1 + '. ' + t(`introduction.card-${prop.index}-${benefit[i]}`)
        )}></div>
    );
  }
  return <div className='benefit'>{template}</div>;
};

const IntroductionScenario = () => {
  let [pos, setPos] = useState('START');
  return (
    <div className='introduction-scenario'>
      {utils.checkMobile() && pos !== 'START' ? <i className='material-icons left'>arrow_back_ios_new</i> : ''}
      <div
        className='scenario-wrapper'
        onScroll={(e) => {
          const target = e.target as HTMLDivElement;
          if (target.scrollLeft < 20) {
            setPos('START');
          } else if (target.scrollWidth - target.offsetWidth - 20 < target.scrollLeft) {
            setPos('END');
          } else {
            setPos('MIDDLE');
          }
        }}>
        <img src={translateSetting.getCurrentLanguage() == 'KOR' ? scenarioKor : scenarioEng} />
      </div>
      {utils.checkMobile() && pos !== 'END' ? <i className='material-icons right'>arrow_forward_ios</i> : ''}
    </div>
  );
};

export default Introduction;
