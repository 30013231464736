import './features.scss';
import mainFeature1 from '../assets/icon/feature-main-1.svg';
import mainFeature2 from '../assets/icon/feature-main-2.svg';
import mainFeature3 from '../assets/icon/feature-main-3.svg';
import mainFeature4 from '../assets/icon/feature-main-4.svg';
import subFeature1 from '../assets/icon/feature-sub-1.svg';
import subFeature2 from '../assets/icon/feature-sub-2.svg';
import subFeature3 from '../assets/icon/feature-sub-3.svg';
import subFeature4 from '../assets/icon/feature-sub-4.svg';
import subFeature5 from '../assets/icon/feature-sub-5.svg';
import subFeature6 from '../assets/icon/feature-sub-6.svg';
import { useTranslation } from 'react-i18next';
import utils from '../core/utils';
import { motionX, motionY, motionScale, motionChildDelayContainer } from 'src/core/animation';
import { motion } from 'framer-motion';

const Features = () => {
  return (
    <section id='features' className='Features'>
      <FeaturesTitle />
      <FeaturesDetail />
    </section>
  );
};

const FeaturesTitle = () => {
  return (
    <div>
      <section className='features-title-section'>
        <div></div>
        <motion.div
          className='features-title'
          initial='offscreen'
          whileInView='onscreen'
          viewport={{ once: false, amount: 0.3 }}
          variants={motionScale()}>
          WHY <b>THINGPLUS?</b>
        </motion.div>
        <MainCard />
      </section>
    </div>
  );
};

const FeaturesDetail = () => {
  const { t } = useTranslation();
  return (
    <section className='features-detail-section'>
      <motion.section className='left-section' variants={motionX()}>
        <div className='chapter' dangerouslySetInnerHTML={utils.createMarkup(t('common.chapter'))}></div>
        <div className='content-box'>
          <motion.div
            className='title'
            initial='offscreen'
            whileInView='onscreen'
            viewport={{ once: false, amount: 0.3 }}
            variants={motionX()}>
            FEATURES
          </motion.div>
          <div className='underline'></div>
          <motion.div
            className='sub-title'
            initial='offscreen'
            whileInView='onscreen'
            viewport={{ once: false, amount: 0.3 }}
            variants={motionX()}>
            {t('features.features-title')}
          </motion.div>
          <motion.div
            className='content'
            initial='offscreen'
            whileInView='onscreen'
            viewport={{ once: false, amount: 0.3 }}
            variants={motionX()}>
            {t('features.features-description')}
          </motion.div>
        </div>
      </motion.section>
      <section className='right-section'>
        <SubCard />
      </section>
    </section>
  );
};

const MainCard = () => {
  const { t } = useTranslation();
  const contents = [
    { icon: mainFeature1, content: t('features.features-main-1') },
    { icon: mainFeature2, content: t('features.features-main-2') },
    { icon: mainFeature3, content: t('features.features-main-3') },
    { icon: mainFeature4, content: t('features.features-main-4') },
  ];
  let template = [];
  for (let i = 0; i < contents.length; i++) {
    template.push(
      <motion.div key={i} className='feature-card' variants={motionY()}>
        <img className='card-icon' src={contents[i].icon} />
        <div className='card-content'>{contents[i].content}</div>
      </motion.div>
    );
  }
  let motionChildDelayContainerConfig = motionChildDelayContainer({ delayChildren: 0.3, staggerChildren: 0.2 });
  if (utils.checkMobile()) {
    motionChildDelayContainerConfig = {};
  }
  return (
    <motion.div
      className='features-group'
      variants={motionChildDelayContainerConfig}
      initial='offscreen'
      whileInView='onscreen'>
      {template}
    </motion.div>
  );
};

const SubCard = () => {
  const { t } = useTranslation();
  const contents = [
    { icon: subFeature1, title: t('features.features-sub-1-title'), content: t('features.features-sub-1-content') },
    { icon: subFeature2, title: t('features.features-sub-2-title'), content: t('features.features-sub-2-content') },
    { icon: subFeature3, title: t('features.features-sub-3-title'), content: t('features.features-sub-3-content') },
    { icon: subFeature4, title: t('features.features-sub-4-title'), content: t('features.features-sub-4-content') },
    { icon: subFeature5, title: t('features.features-sub-5-title'), content: t('features.features-sub-5-content') },
    { icon: subFeature6, title: t('features.features-sub-6-title'), content: t('features.features-sub-6-content') },
  ];
  let template = [];
  for (let i = 0; i < contents.length; i++) {
    template.push(
      <motion.div key={i} className='feature-card' variants={motionY()}>
        <img className='card-icon' src={contents[i].icon} />
        <div className='card-title'>{contents[i].title}</div>
        <pre className='card-content'>{contents[i].content}</pre>
      </motion.div>
    );
  }

  return (
    <motion.div
      className='features-group'
      variants={motionChildDelayContainer({ delayChildren: 0.3, staggerChildren: 0.2 })}
      initial='offscreen'
      whileInView='onscreen'>
      {template}
    </motion.div>
  );
};

export default Features;
